// @flow

import { Plural, Trans, t } from '@lingui/macro';
import cn from 'classnames';
import * as React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import * as Backend from '../../backend/legacyJsonBackend';
import infoIcon from '../../components/info-blue.svg';
import * as colors from '../../design/colors';
import { allElements, allQuestionsOf } from '../../form/elements';
import { findElementWithIdx } from '../../form/hiddenElements';
import i18n from '../../i18n';
import { ConnectedElement } from '../../question-elements/FormElement';
import { mandatoryHeadingStyle } from '../../question-elements/mandatoryMark';
import { optionMaxWidth } from '../../question-elements/multiple-choice';
import { stripHtml } from '../../util';
import pageBreakIcon from './pageBreak.png';

const style = {
  previewContainer: {
    backgroundColor: colors.white,
    padding: 30,
    border: '1px solid #d0d0d0',
  },
  previewPage: {
    paddingTop: 50,
    paddingRight: 20,
    paddingLeft: 20,
  },
  pageNumber: {
    textAlign: 'right',
    color: colors.ns.black,
  },
  pageBreak: {
    marginTop: 46,
    marginBottom: 10,
    display: 'flex',
    color: colors.ns.black,
    alignItems: 'center',
  },
  pageBreakLine: {
    flex: '1',
    borderBottom: '1px dotted #d0d0d0',
    marginLeft: 7,
  },
  pageBreakIcon: {
    marginRight: 8,
    height: 24,
    width: 19,
  },
  explanation: {
    display: 'flex',
    color: colors.ns.black,
    fontSize: 16,
    backgroundColor: '#f2f8fc',
    borderRadius: 4,
    padding: [8, 18],
    marginBottom: 20,
    alignItems: 'center',
    lineHeight: 'normal',
    maxWidth: optionMaxWidth,
  },
  infoIcon: { marginRight: 15, width: 20, height: 20 },
  infoMandatory: {
    marginBottom: 50,
    fontSize: 16,
  },
  mandatory: mandatoryHeadingStyle,
  mandatoryExtra: {
    fontSize: '2.2rem',
  },
};

type Classes = { [$Keys<typeof style>]: string };

type QuestionWithMultipleChoiceFlag = Backend.Question & {
  multipleChoice: boolean,
};
const questionContainingAnswerOptionId: (
  number,
  Backend.Element[],
) => QuestionWithMultipleChoiceFlag = (answerOptionId, elements) => {
  const question = allQuestionsOf(elements).find(question =>
    question.answerOptions.find(
      answerOption => answerOption.answerOptionId === answerOptionId,
    ),
  );
  if (question == null) {
    throw new Error(
      `Could not find question containing answerOptionId ${answerOptionId}`,
    );
  }
  const elementOfQuestion = elements.find(
    element =>
      element.questions.length === 1 &&
      element.questions[0].questionId === question.questionId,
  );
  if (elementOfQuestion == null) {
    throw new Error(
      `Could not find element containing question with id ${question.questionId}`,
    );
  }
  const elementType = elementOfQuestion.elementType;
  return { ...question, multipleChoice: elementType === 'CHECKBOX' };
};

const textOfAnswerOptionId = (answerOptionId, question) => {
  const answerOption = question.answerOptions.find(
    answerOption => answerOption.answerOptionId === answerOptionId,
  );
  if (answerOption == null) {
    throw new Error(
      `Could not find answerOption with answerOptionId ${answerOptionId} in question ${question.questionId}`,
    );
  }
  return i18n._(t`«${answerOption.text}»`);
};

const listedAnswerOptions = (answerOptionIds, question) => ({
  singular: textOfAnswerOptionId(answerOptionIds[0], question),
  plural:
    answerOptionIds.length === 2
      ? i18n._(
          t`${textOfAnswerOptionId(
            answerOptionIds[0],
            question,
          )} eller ${textOfAnswerOptionId(answerOptionIds[1], question)}`,
        )
      : answerOptionIds
          .slice(0, answerOptionIds.length - 1)
          .map(answerOptionId => textOfAnswerOptionId(answerOptionId, question))
          .join(', ') +
        ' ' +
        i18n._(
          t`eller ${textOfAnswerOptionId(
            answerOptionIds[answerOptionIds.length - 1],
            question,
          )}`,
        ),
});

const MultipleChoiceExplanation = ({ answerOptionIds, question }) => {
  return (
    <Plural
      value={answerOptionIds.length}
      one={`Dette elementet vises kun dersom alternativet ${
        listedAnswerOptions(answerOptionIds, question).singular
      } er valgt i spørsmålet «${stripHtml(question.text)}»`}
      other={`Dette elementet vises kun dersom minst ett av alternativene ${
        listedAnswerOptions(answerOptionIds, question).plural
      } er valgt i spørsmålet «${stripHtml(question.text)}»`}
    />
  );
};

const SingleChoiceExplanation = ({ answerOptionIds, question }) => {
  const optionText = listedAnswerOptions(answerOptionIds, question);
  return (
    <Trans>
      Dette elementet vises kun dersom alternativet{' '}
      {answerOptionIds.length > 1 ? optionText.plural : optionText.singular} er
      valgt i spørsmålet «{stripHtml(question.text)}»
    </Trans>
  );
};

const HideShowExplanation = injectSheet(style)(
  (p: {
    visibilityAnswerOptionIds: number[],
    question: QuestionWithMultipleChoiceFlag,
    classes: Classes,
  }) => {
    return (
      <div className={p.classes.explanation}>
        <img src={infoIcon} alt="info" className={p.classes.infoIcon} />
        <p>
          {p.question.multipleChoice ? (
            <MultipleChoiceExplanation
              answerOptionIds={p.visibilityAnswerOptionIds}
              question={p.question}
            />
          ) : (
            <SingleChoiceExplanation
              answerOptionIds={p.visibilityAnswerOptionIds}
              question={p.question}
            />
          )}
        </p>
      </div>
    );
  },
);

export const forTesting = { HideShowExplanation };

const Page = (p: {
  pages: Backend.Page[],
  pageElements: Backend.Element[],
  allElements: Backend.Element[],
}) => (
  <div>
    {p.pageElements.map(element => (
      <React.Fragment key={element.elementId}>
        <ConnectedElement
          element={element}
          postHeading={
            element.visibilityAnswerOptionIds.length > 0 && (
              <HideShowExplanation
                visibilityAnswerOptionIds={element.visibilityAnswerOptionIds}
                allElements={p.allElements}
                question={questionContainingAnswerOptionId(
                  element.visibilityAnswerOptionIds[0],

                  element.visibilityParent
                    ? [findElementWithIdx(p.pages, element.visibilityParent)]
                    : [],
                )}
              />
            )
          }
          disabled={false}
        />
      </React.Fragment>
    ))}
  </div>
);

const PageNumber = injectSheet(style)(
  (p: { pageIndex: number, classes: Classes }) => (
    <p className={p.classes.pageNumber}>
      <Trans>Side {p.pageIndex + 1}</Trans>
    </p>
  ),
);

const PageBreak = injectSheet(style)((p: { classes: Classes }) => (
  <div className={p.classes.pageBreak}>
    <img src={pageBreakIcon} alt="Sider" className={p.classes.pageBreakIcon} />
    <Trans>Sideskift</Trans>
    <hr className={p.classes.pageBreakLine} />
  </div>
));

const MandatoryInfoText = (p: { classes: Classes, pages: Backend.Page[] }) => (
  <div className={p.classes.infoMandatory}>
    <Trans>Obligatoriske felter er merket med stjerne</Trans>
    <span className={cn(p.classes.mandatory, p.classes.mandatoryExtra)}></span>
  </div>
);

const mandatoryQuestionExists = (page: Page) =>
  page.elements.some(element =>
    element.questions.some(question => question.mandatory),
  );

const AllPages = injectSheet(style)(
  (p: { classes: Classes, pages: Backend.Page[] }) => {
    const allTheElements = allElements(p.pages);

    return (
      <div>
        {p.pages.map((page, idx) => (
          <div key={`form-page-${idx}`} className={p.classes.previewPage}>
            {p.pages.length > 1 && <PageNumber pageIndex={idx} />}
            {mandatoryQuestionExists(page) && (
              <MandatoryInfoText classes={p.classes} pages={p.pages} />
            )}
            <Page
              pages={p.pages}
              pageElements={page.elements}
              allElements={allTheElements}
            />
            {idx < p.pages.length - 1 && <PageBreak />}
          </div>
        ))}
      </div>
    );
  },
);

const FormPreview = (p: { classes: Classes, pages: Page[], title: string }) => (
  <div>
    <h1>{p.title}</h1>
    <hr />
    <AllPages pages={p.pages} />
  </div>
);

const StyledFormPreview = injectSheet(style)(FormPreview);

export const FormPreviewContainer = connect(({ answer: { form } }) => ({
  pages: form == null ? [] : form.pages,
  title: form == null ? '' : form.meta.title,
}))(StyledFormPreview);

export default FormPreviewContainer;
